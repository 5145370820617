/* You can add global styles to this file, and also import other style files */

@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");
/*@import url("assets/bower_components/font-awesome/css/font-awesome.min.css");*/
@import url("../node_modules/@fortawesome/fontawesome-free/css/all.min.css");
@import url("assets/bower_components/Ionicons/css/ionicons.min.css");
@import url("assets/dist/css/AdminLTE.min.css");
@import url("assets/dist/css/skins/_all-skins.min.css");
@import url("assets/bower_components/morris.js/morris.css");
@import url("assets/bower_components/jvectormap/jquery-jvectormap.css");
@import url("assets/bower_components/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css");
@import url("assets/bower_components/bootstrap-daterangepicker/daterangepicker.css");
@import url("assets/plugins/bootstrap-wysihtml5/bootstrap3-wysihtml5.min.css");
@import url("assets/plugins/iCheck/square/blue.css");
@import url("../node_modules/sweetalert2/dist/sweetalert2.min.css");


table{
    font-size: 14px;
}

.btn-danger{
    color: white !important;
}